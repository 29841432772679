import React, { useState } from 'react';
import { ReactComponent as Contact } from '../images/contact.svg'
import { ReactComponent as Arrow } from '../images/arrow.svg'
import tw from 'twin.macro'

import Layout from "../components/layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default function FaqPage({location}) {
    const [switchVal,setSwitchVal] = useState("1")


    const styles = {
        navLi: (bool)=>[
            tw`block font-bold text-lg py-3 border-t border-t-[rgba(0,0,0,0.1)] xl:border-none hover:cursor-pointer hover:text-blue`,
            bool && tw`after:w-1 after:h-full after:left-[-8px] after:top-0 after:transform after:translate-x-[-100%] after:absolute after:bg-blue`,
            bool && tw`xl:after:hidden xl:bg-white xl:rounded`,
            bool && tw`text-blue`,
        ],
        arrow: ()=>[
            tw`w-3 absolute right-4 top-7`
        ],
        faqTitle: ()=>[
            tw`text-lg text-gray font-semibold flex items-center justify-between bg-white py-5 px-6 shadow-faq relative pr-10`
        ],
        faqCont: ()=>[
            tw`bg-blue-darkest bg-opacity-5 py-2.5 px-6 text-base leading-7`
        ]
    }

    return (
        <Layout>
            <GatsbySeo canonical={location.href} title="faq" description="faq" />
            <div className="pt-[112px] pb-12 text-center">
                <h1 className="text-[42px] font-extrabold font-raleway text-blue-darkest leading-snug pb-6 sm:text-[calc(18px+2vw)]">Frequently asked questions</h1>
                <p>Find the problem you might have about naturalvoicer. We have the official answers. </p>
            </div>
            <div className="bg-light">
                <div className="w-full container mx-auto py-24 flex items-start justify-between xl:flex-col">
                        <div className="w-[216px] sticky top-14 xl:w-full xl:static xl:mb-8 xl:text-center">
                            <ul className="xl:grid xl:grid-cols-3 xl:gap-4 sm:grid-cols-1">
                                <li onClick={()=>{setSwitchVal("1")}}><span css={styles.navLi(switchVal==="1")}>Product FAQs</span></li>
                                <li onClick={()=>{setSwitchVal("2")}}><span css={styles.navLi(switchVal==="2")}>Sales FAQs</span></li>
                                <li onClick={()=>{setSwitchVal("3")}}><span css={styles.navLi(switchVal==="3")}>Account FAQs</span></li>
                            </ul>
                        </div> 
                        <div className="w-[936px] xl:w-full">
                            <div>
                                <div className={`${switchVal==='1'?'':'hidden'}`}>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>What is text to speech? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p className="pb-4">A text-to-speech tool, also known as a text reader or text-to-speech software, is a technology for reading digital text aloud.</p>
                                            <p>It was originally an assistive technology developed for the visually impaired to help them understand and read texts on a mobile phone or computer. Now it is also widely used in other fields, such as making eLearning courseware, YouTube voiceovers, etc.</p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Which languages are supported by NaturalVoicer? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p className="pb-4">We support US English, UK English, Spanish, Spanish Latin American, French, French Canadian, Arabic, Portuguese, Chinese, Chinese HongKong, Chinese TaiWan, Hindi, Brazillian, Aemenian, Australian English, Bangla Bangladesh, Bangla India, Czech, Danish, Deutsch, Dutch, Estonian, Fillipion, Finnish, Greek, Hungarian, Indonesian, Italian, Janpanese, Korean, Latin, Netpali, Norwegain, Polish, Romanian, Russian, Sinhala, Slovak, Swedish, Tamil and more. </p>
                                            <p><a href="#" className="text-blue underline">Try it now &gt;&gt; </a></p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Why there is no sound when I click play? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <ul className="list-num pb-4">
                                                <li>Check your speakers and volume. </li>
                                                <li>Check your microphone and permission for accessing the microphone.</li>
                                                <li>If there is still no sounds, please contact us describing the problem in detail.</li>
                                            </ul>
                                            <p>Mail to: <a href="mailto:support@naturalvoicer.com">support@naturalvoicer.com</a></p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>There is a 2000-characters limit, how can I get more characters? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Each user can only convert 2000 characters per day for free. If you need more characters, please upgrade to our premium plans (0.1million characters/$9.99, 0.5million characters/$19.99). Or wait till next day. </p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                        <h3 css={styles.faqTitle}>Can I use the download MP3 files for my own projects?  <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Yes. It doesn't matter if you have a paid account. You can use the downloaded MP3 files in any application or platform, like YouTube or e-Learning apps. </p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Can you add more voices to English or other languages? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>The voices we currently use are provided by other companies, and we will work hard to add more voices in the future.</p>
                                        </div>
                                    </details>
                                </div>
                                <div className={`${switchVal==='2'?'':'hidden'}`}>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>What payment methods are supported?<Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>We support Paypal, Mastercard, Visa, Maestro, American Express, Discover, JCB, UnionPay and much more.</p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Is it safe to make a purchase? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Your personal information is strictly confidential on the website so don't worry about it. </p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Can I get a refund if I am not satisfied? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>If you are not satisfied with our products, you can request a refund within 14 days after purchase. To request a refund, please contact us at <a href="mailto:support@naturalvoicer.com">support@naturalvoicer.com</a></p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>What is the auto-renwal? Can I cancel the subscription? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>By default, all subscriptions are set to auto-renew at the end of the billing period but you can turn this off at any time by selecting 'Cancel Subscription' from your account's Billing Information.</p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                        <h3 css={styles.faqTitle}>What's the difference between the free and paid versions?  <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Free users, whether registered or not, can use the basic text-to-speech function to convert 2,000 characters per day. But the purchase users can convert up to 1 million characters per month. Paid users can also get 100MB - 500MB free cloud storage to place the uploaded documents. </p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Which plan is sutiable for me? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p className="pb-4">Our pricing plan is determined based on the number of characters that can be converted. Generally speaking, a 1000-word document has nearly 5000 characters. If it is in PDF or e-book format, the number of characters will be even more. You can choose the number of characters above according to your actual needs.</p>
                                            <ul className="list-ok">
                                                <li>Pro plan: 0-100,000 characters a month; $9.99/month.  </li>
                                                <li>Plus plan: 0-500,000 characters a month; $19.99/month.</li>
                                                <li>Premium plan: 0-1,000,000 characters a month; $59.99/month.</li>
                                            </ul>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                        <h3 css={styles.faqTitle}>How do I upgrade from Pro to Plus/Premium? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>First of all, make sure you are logged into NaturalVoice website. Then hit the Buy Now button under Plus or Premium plan. After the purchase is successful, your original Pro plan will be cancelled and you have successfully updated to Plus/Premium. </p>
                                        </div>
                                    </details>
                                </div>
                                <div className={`${switchVal==='3'?'':'hidden'}`}>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Do I need to register an account before using?<Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>No, but registering an account would allow you access more features. You can also get timely information about website updates, coupons & discounts, points redemption, etc.</p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>Can I upgrade or downgrade my plan?  <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Yes. First of all, be sure you're logged into our website. To upgrade or downgrade your plan, just order the plan you need. The original plan will be automatically cancelled after the purchase is completed, and the upgrade/downgrade will be completed. If not, please contact us for help. </p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>I finished the purchase but I got no login information? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Generally, users who use NaturalVoicer for the first time will receive a confirmation email containing purchase information, username and password after purchase. Please double check the email address, including the spam folder. If you still don’t receive it within the next 2 hours, please contact us to resend the email.</p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                            <h3 css={styles.faqTitle}>How many users per account? <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Each account allows single user access only. </p>
                                        </div>
                                    </details>
                                    <details className="mb-5">
                                        <summary>
                                        <h3 css={styles.faqTitle}>I couldn’t log in my account, please help.  <Arrow css={styles.arrow} fill="currentColor"/></h3>
                                        </summary>
                                        <div css={styles.faqCont}>
                                            <p>Make sure your username and password are correct. You can try to click “Forget password” to reset the password. If you still couldn’t login, please contact us and we will help you. </p>
                                        </div>
                                    </details>
                                </div>
                            </div>
                            <div className="flex justify-center items-center bg-white rounded-lg py-8 px-[5%] md:flex-col md:text-center">
                                <Contact className="w-[80px] md:mb-4"/>
                                <div className="pl-8 w-[560px]">
                                    <div className="font-raleway font-bold text-xl pb-3">Cannot Find What Your Need?</div>
                                    <p className="text-gray">If none of the solutions can help you,fell free to contact us and we are more than glad to help you. </p>
                                </div>
                                <a href="mailto:support@naturalvoicer.com" className="ml-auto bg-blue text-lg text-white hover:bg-blue-darkest hover:text-white h-10 w-32 rounded flex justify-center items-center md:ml-0 md:mt-8">Mail us</a>
                            </div>              
                        </div>
                </div>
            </div>
        </Layout>
    )
}



